$grey-background: #f5f5f5;
$grey-border: #ddd;
$grey-text: #898989;
$grey-dark: #444;
$main-light: #d9f2fb;
$main: #00a9e0;
$main-dark: #007da6;
$success: #7fd81e;
$error: #ff5100;
$warning: #f6c223;

svg {
  display: block;
  // width: 1em;
  // height: 1em;
  fill: currentColor;
}

.select {
  position: relative;
  display: inline-block;
  width: 320px;
  
  &:focus {
    outline: 0;
    
    & .selection {
      box-shadow: 0 0 1px 1px $main;
    }
  }
}

.label {
  display: block;
  margin-bottom: 3px;
  font-weight: 600;
}

.selection {
  position: relative;
  padding: 2px;
  border: 1px solid $grey-border;
  border-radius: 8px;
  background: #fff;
}

.value {
  position: relative;
  display: inline-block;
}

.delete {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 10px;
  font-size: 10px;
  cursor: pointer;
}

.placeholder {
  padding: 5px 10px;
  color: $grey-text;
}

.arrow {
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  padding: 10px;
  font-size: 10px;
  color: $grey-text;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: solid $grey-border;
  border-width: 0 1px;
  background: #fff;
}

.option {
  padding: 10px 15px;
  border-bottom: 1px solid $grey-border;
  cursor: pointer;

  &.selected {
    border: 1px solid $main;
    margin: -1px -1px 0;
    background: $main-light;
  }
  
  &.focused {
    background: $grey-background;
  }
}

.checkbox {
  content: '';
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  border: 1px solid $grey-border;
  border-radius: 2px;
  margin: 2px 12px 0 0;
  color: #fff;
  font-size: 10px;
  
  .selected & {
    border-color: $main-dark;
    background: $main;
  }
}