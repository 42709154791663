
.product-quantity {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #333333;
    border: none;
    font-size: 1.4rem;
    background: transparent;
    border-left: 0;
}

.product-quantity__btn {
    border: none;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 100%;
    border-radius: 0;
}


.product-quantity__number {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.4rem;
    color: #333333;
    font-size: .9rem;
    overflow: hidden;
    text-align: center;
    background-color: transparent;
}

.product__btn {
    border: none;
    background-color: transparent;
}

.product__btn .shop__product-btn-text {
    color: #20A8D8;
}

.product__second-btn {
    border-color: #20A8D8;
}
.product__second-btn .shop__product-btn-text {
    color: #20A8D8;
}

.product-quantity__icon {
    width: .7rem;
    height: .7rem;
    fill: #111111;
}

.product__info {
    margin: 5px 12px 0 0;
    float: right;
    max-width: 164px;
}