.call {
  text-align: center;
}

.status {
  text-align: center;
  background: #f4f4f6;
  display: block;
  width: 200px;
  padding: 0.5em;
  border-radius: 6px;
  margin: 2em auto 0;
}

.outboundFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282b32;
}

.hungup {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: all 0.3s;
  background-color: #E72E04;
}

.outboundFooter button {
  color: white;
}

button.fa-video, button.fa-call,
button.fa-microphone {
  background-color: rgb(76, 175, 80);
}

button.hangup {
  background-color: rgb(244, 67, 54);
}

.call-modal .btn-action,
.outboundFooter .btn-action {
  height: 45px;
  width: 45px;
  line-height: 10px;
  margin: 0 8px;
  font-size: 1.2em;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: .25s;
}

.btn-action:focus {
  outline: none;
  border: none;
}

.outboundFooter {
.btn-action {
  
  &:hover {
    opacity: 0.8;	
  }
  
  &.hangup {
    background-color: #E81123;
  }
  
  &:not(.hangup) {
    
    &.disable {
      background-color: #E81123;
    }
  }
}
}