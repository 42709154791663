/* notification */
.notification-popup {
    position: absolute;
    top: 36px;
    right: 4px;
    width: 320px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    user-select: none;
    z-index: 1001;
    &::before {
        content: "";
        position: absolute;
        top: -6px;
        right: 5%;
        width: 0px;
        height: 0px;
        pointer-events: none;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #20A8D8 transparent;
        overflow: hidden;
        z-index: 1;
    }
}

@media (max-width: 991.98px) {
    .notification-popup {
        right: -12px;
    }
}

@media (max-width: 575.98px) {
    .notification-popup {
        top: 36px;
        right: -10px;
        width: 295px;
    }
}

.notification-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0px 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #20A8D8;
    h6 {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
    }
}

.notification-content {
    max-height: 180px;
    overflow: hidden auto;
    background-color: #fff;
}

.notification-item-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #aaaaaa;
    h2 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
    }
}

.notification-item-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin: 0px 8px;
}

.notification-list {
    width: 100%;
    background-color: #fff;
}

.notification-link {
    box-sizing: border-box;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    outline: none;
    color: #333333;
    &:hover {
        color: #333333;
    }
}

.notification-item {
    display: flex;
    position: relative;
    padding: 10px;
    // cursor: pointer;
    background-color: #fff;
    &:hover {
        background-color: #f1f1f1;
    }
}

.notification-delete {
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    z-index: 11;
    svg {
        width: 100%;
        height: 100%;
        vertical-align: top;
        fill: #999999;
    }
}

.notification-item-info-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    span {
        font-size: 14px;
        font-weight: 400;
    }
}

.notification-item-info-time {
    font-size: 12px;
    color: #999999;
}

.no-notification {
    padding: 10px;
    text-align: center;
}

.mobile-notification {
    content: "";
    position: absolute !important;
    top: 18px;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .icon-bell {
        width: 20px;
        height: 20px;
        font-size: 20px;
    }
    
}

.notification-count {
    position: absolute !important;
}

.mobile-notification .nav-item {
    min-width: auto !important;
}


@media (min-width: 992px) {
    .mobile-notification  {
        display: none;
    }
}
