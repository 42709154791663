.password-meter {
  width: 100%;
}

.password-meter__bar {
  width: 100%;
  background: #393E46;
  margin-top: -10px;
  margin-bottom: 1rem;
  position: relative;
}

.password-meter__bar__inner {
  width: 0;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.password-meter__label {
  color: #666;
  font-size: 1.2rem;
}