form {
    max-width: 485px;
    background-color: #fff;
  }
  
  ul {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
  }
  
  li:not(:last-child) {
    margin-bottom: 5px;
  }
  
  
  input[type="radio"] {
    margin: 0 10px 0 0;
  }
  
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  