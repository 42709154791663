.C\(\#4e5b65\) {
    color: #4e5b65;
  }
  .D\(ib\) {
    display: inline-block;
  }
  .Fl\(end\) {
    float: right;
  }
  .H\(35px\) {
    height: 35px;
  }
  .Pos\(r\) {
    position: relative;
  }
  .Ta\(c\) {
    text-align: center;
  }
  .W\(100\%\) {
    width: 100%;
  }