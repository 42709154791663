


.radio-options {
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    border-bottom: 1px solid #e4e7ea;
}

.radio-option__item {
    text-transform: capitalize;
    position: relative;
    display: inline-block;
    padding: 3px 12px;
    background-color: #fff;
    border: 2px solid ;
    border-radius: 0;
    color: #00a9e0;
    font-weight: 400;
    margin: 0px 5px 5px;
    transition: all .5s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
    cursor: pointer;
    box-shadow: inset 0px 0px 4px #d9f2fb;

    &:hover {
        background-color: #e6e6e6;
    }
}

.weight-option__item--active,
.radio-option__item--active {
    background-color:#00a9e0;
    border: 2px solid #00a9e0;

    .weight-option__item__label,
    .radio-option__item__label {
        color: #fff;
    }

    &:hover {
        background-color: #e6e6e6;
    }
}

.radio-options__title {
    flex-basis: 100%;
    margin-right: 0;
    text-align: center;
}

.select-option {
    margin: 0 0 10px;
    padding: 0;
    border-bottom: 1px solid #e4e7ea;

    .product-weight-quantity {
        justify-content: center;
    }
}

.weight-product__select {
    position: relative;
    width: auto;
    height: auto;
    margin: 0px 5px 5px;
    padding: 5px 29px;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #00a9e0;
    border-radius: 0;
    box-shadow: inset 0px 0px 4px #d9f2fb;
    transition: all .5s;
}

.weight-options__title {
    flex-basis: 100%;
    margin-right: 0;
    text-align: center;
}

.weight-option__item__label {
    font-size: .9rem;
    color: #313131;
}

.checkbox-option__input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.checkbox-option {
    justify-content: center;
    align-items: center;
    margin: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e4e7ea;
}

.checkbox-option__item {
    margin: 0 8px 8px;
}

.checkbox-option__label {
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    width: auto;
    margin: 0;
    font-size: 1.6rem;
    color: #00a9e0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-option__name {
    font-size: 14px;
    text-transform: capitalize;
    color: #202020;
}

.checkbox-option__checkmark {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    border-radius: 0;
    border: 2px solid #00a9e0;
}

.checkbox-option__input:checked ~ .checkbox-option__checkmark {
    background-color: #00a9e0;
}

.product-color {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e4e7ea;
    &__title {
        flex-basis: 100%;
        font-size: .9rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    &__item {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border-radius: 100%;
        margin: 0px 5px 5px;
        cursor: pointer;
    }

    &__item_active {
        border: 2px solid transparent;
        padding: 3px;
    }

    &__inner {
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
}