 .color-selector {

    display: inline-block;
    margin: 0 auto;
    border: "1px splod #000";
    border-radius: 3px;
    box-shadow: 0 0 5px #333;
    position: relative;
    padding: 6px 32px 6px 10px;
    font-family: verdana;
    background: white;
    
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
      top: 17px;
    }
    
    .circle {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin-right: 10px;
    }
    
    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;
    }
    
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  