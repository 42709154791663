.register {
  display: flex;
  transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s;
  opacity: 0;
  z-index: 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media only screen and (max-width: 500px){
  .register { 
    height: 100%; 
  }
}
.register.active {
  opacity: 1;
  z-index: 1;
}
.register-wrap {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 20px 15px;
}
h1, h3 {
  font-family: averta-bold;
}
.header p {
  margin: 10px 0 0 0;
  opacity: 0.8;
}
.form-register {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
}
.form .cards {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #393E4670;
}
.form .input-field {
  display: grid;
}
.form .label {
  margin: 10px 0 0 0;
}
.form input {
  padding: 5px 0 10px 0;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}
.form input::placeholder {
  color: #00000070;
}
.submit-button {
  display: flex;
}
.submit-button .btn {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #393E46;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  border-radius: 2rem;
}
.forgot {
  text-decoration: underline;
}
.footer {
  display: flex;
  gap: 5px;
  margin: 0 0 0 0;
}
.footer .btn {
  text-decoration: underline;
  cursor: pointer;
}

.response {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
  padding: 10px 0 20px 0;
  opacity: 0;
}
.response.active {
  opacity: 1;
}


/* darkmode */
.register.dark {
  background: #1e2126;
}
.register.dark .form .cards {
  border-color: #393E46;
}
.register.dark * {
  color: #ffffffdd;
}
.register.dark .form input::placeholder {
  color: #ffffff70;
}


@media screen and (min-width: 320) {
  .register {
    height: 100%;
  }
}