.customer-group-filter-container {
    background-color: var(--wsr-color-D80, #FFFFFF);
    position: relative;
    max-height: calc(100vh - (24px*2));
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    
    .filter-header {

        .filter-header-content {

            padding: 6px 4px 4px 8px;
            .filter-header-text {
                color: #162D3D;
            }

        }
    }

    .filter-body {
        flex: 1;
        display: flex;
        flex-direction: column;

        .filter-body-slicer {
            transition: opacity 100ms linear;
            background-color:#f3f4f5;
            min-height: 1px;
            width: 100%;
            margin: 0;
            border: 0;
        }

        .layout-content-wrapper {
            height: inherit;
            width: inherit;
            flex: 1;
            overflow-y: auto;

            .direction-vertical {
                flex-direction: column;
                gap: var(--Box579750403-gap);
                display: flex;

                .filter-body-content {
                    padding-top: 2px;
                    padding-right: 0px;
                    padding-left: 0px;
                }

                .choose-field-search {
                    display: block!important;

                    .choose-field-search-container {

                        .popover-content-search-input {
                            display: block;
                            position: relative;

                            .field-search {
                                border-radius: 40px;
                                position: relative;
                                display: flex;
                                border: solid 1px #C1E4FE;
                                background-color: #FFFFFF;
                                color:  #162D3D;

                                .search-container {
                                    padding: 0 6px;
                                    display: flex;
                                    width: 100%;

                                    .search-icon {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                        color: var(--wsr-color-10, var(--wsr-color-B10, #3899EC))
                                    }

                                    .wsr-input {
                                        padding: 5px 3px 5px 6px;
                                        display: block;
                                        flex: 1 1 auto;
                                        margin: 0;
                                        border: 0;
                                        outline: 0;
                                        background: 0 0;
                                        box-shadow: none;
                                        width: inherit;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .content-container {
            margin-top: 4px;
            width: 500px;
            height: 555px;
            overflow-y: hidden;

            .dropdown-layout-options {
                max-height: 555px;
                overflow: auto;

                .dropdown-layout-item {
                    color: var(--wsr-color-D10, #162D3D);
                    line-height: 1.5;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                    padding: 6px 12px 6px 12px;
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    
                    &:hover {
                        background-color: #EAF7FF;
                    }

                    &.active { 
                        background-color:#3899EC !important;
                        color:#FFFFFF !important
                    }

                    .dropdown-layout-item-label {
                        gap: 24px;
                        justify-items: flex-start;
                        grid-auto-rows: 24px;
                        display: grid;
                        grid-template-columns: repeat(12,minmax(0,1fr));

                        .dropdown-layout-item-label-container {
                            grid-area: span 1 / span 11 / auto / auto;
                        }
                    }
                }
            }

        }
    }

}