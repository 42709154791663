.modal-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden auto;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 250ms ease 0s;
    z-index: 1111;
    
    &-tab {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 3.5rem;
        margin-top: 0.5rem;
        padding: 0 1rem;
        &__item {
            font-size: 1.6rem;
            cursor: pointer;
            &--active {
                border-bottom: 0.2rem solid $color-orange;
            }
        }
    }
    &-center {
        position: relative;
        display: flex;
        align-items: center;
        width: auto;
        min-height: calc(100% - 4rem);
        margin: 2rem 1rem;
        max-width: 27rem;
        margin: 2rem auto;
        &--big {
            @media (min-width: 576px) {
                max-width: 90rem;
                margin: 4rem auto;
                min-height: calc(100% - 8rem);
            }
        }
        &--small {
            @media (min-width: 576px) {
                max-width: 42rem;
                margin: 4rem auto;
                min-height: calc(100% - 8rem);
            }
        }
    }
    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 40px 55px 16px;
        overflow: hidden;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 0 4rem rgba(170, 170, 170, 0.4);

        &__title {
            font-weight: 600;
            font-size: 2rem;
            text-align: center;
        }
        &__header {
            padding: 1.5rem;
        }
        &__body {
            position: relative;
            padding: 1.5rem;
        }
        &__footer {
            padding: 1.5rem;
        }
    }
    &-close {
        content: "";
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        z-index: 11;

        &-icon {
            width: .8rem;
            height: .8rem;
            fill: #333333;
        }
    }

    .form__group:not(:last-child) {
        margin-bottom: 3rem;
    }
}

.modal-box-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
    font-size: 2rem;
    font-weight: 500;
}

.modal-photo {
    &__footer {
        margin-top: 2rem;
        &-box {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
    &__body {
        padding: 3rem 0;
        &-box {
            display: flex;
            flex-direction: column;
        }
    }
    &__header {
        padding: 2rem;
        background-color: #e6e6e6;
        &-title {
            font-size: 2.2rem;
            margin-bottom: 2rem;
            color: #7F9B9A;
        }
    }
    &__tab {
        display: flex;
        &-item {
            flex: 1 0 auto;
        }
        &-link {
            padding: 0.5rem;
            color: #000000;
            &--active {
                border-bottom: 1px solid #000000;
            }
            &:hover {
                color: #000000;
            }
        }
    }
}

.modal-verify {
    &__text {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        span {
            color: $color-orange;
        }
    }

    &__phone {
        font-size: 14px;
        font-weight: 500;
        color: $color-orange;
    }

    .form__group {
        margin-bottom: 2rem;
    }
}

/* MODAL PHOTO */

.modal-tab {
    display: flex;
    &__item {
        flex: 1 0 auto;
    }
    &__link {
        padding-right: 2rem;
        border-bottom: 2px solid transparent;
        color: #333333;
        &--active {
            border-color: #01A8A3;
        }
        &:hover {
            color: #333333;
        }
    }
}

.modal-photo-search {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 43rem;
    height: 40px;
    &-icon {
        width: 2.4rem;
        height: 2.4rem;
    }
    .search-loop {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: default;
        pointer-events: none;
        z-index: 3;
    }
    .search-input {
        width: 100%;
        height: 100%;
        padding: 0 40px;
        border-radius: 4px;
        border: 0.1rem solid #01A8A3;
        outline: none;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        background: transparent;
        &::placeholder {
            font-size: 12px;
            color: #333333;
        }
    }
}

.drag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__icon-box {
        margin-bottom: 1rem;
    }
    &__icon {
        width: 5rem;
        height: 5rem;
        fill: #748A9D;
    }
    &__text {
        margin-bottom: 1rem;
    }
    &__btn {
        padding: 0.8rem 3rem;
        margin-top: 1rem;
        border: 1px solid #748A9D;
        background: linear-gradient(#39434B, #4A5A6A);
    }
}

.upload-photo {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    &__item {
        flex-direction: column;
        margin: 0 1rem 1rem 0;
        border: 1px solid #7F9B9A;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    }
    &__info {
        background-color: #F5F5F5;
        padding: 0.4rem 0.5rem 1rem;
    }
    &__image {
        position: relative;
        width: 15rem;
        height: 15rem;
        &-check {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            opacity: 0.2;
            z-index: 11;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    &__text {
        font-size: 1.4rem;
    }
    &__count {
        font-size: 1.2rem;
        color: #01A8A3;
    }
}

.resend-code__btn {
    padding: 5px 10px;
    border-radius: 2em;
    margin: 5px 0 10px;
}


.modal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    border: none;
    min-width: 88%;
    min-height: 36px;
    font-family: inherit;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    border-radius: 2em;
    text-transform: uppercase;
    transition: all .3s;
    background-color: $color-general;

    &:hover {
        background-color: darken($color-general, 10%);
    }
}