  .incomingCard {
    position: fixed;
    overflow: hidden;
    width: 250px;
    height: fit-content;
    max-height: 400px;
    min-height: 122px;
    top: 0;
    right: 10%;
    border-radius: 15px;
    background-color: #2f323a;
    z-index: 1030;
  }
  
  .incomingHeader {
    position: relative;
    width: 100%;
    height: fit-content;
    max-height: 80%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: transparent;
  }

  .phoneNumber {
    width: 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }
  
  .calling {
    width: 100%;
    font-family: 'Hind', sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: #fff;
    -webkit-animation: opacity 2.5s infinite linear;
    -o-animation: opacity 2.5s infinite linear;
    animation: opacity 2.5s infinite linear;
  }
  
  @keyframes opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  
  .incomingFooter {
    position: relative;
    width: 100%;
    height: 20%;
    border-top: 1px solid #24262c;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #282b32;
    padding: 5px;
  }
  
  .bouton {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 40px;
    top: 50%;
    cursor: pointer;
    border-radius: 40px;
    transform: translateY(-50%);
  }
  
  .raccrocher {
    left: 7%;
    transition: all 0.3s;
    background-color: #E72E04;
  }
  
  .decrocher {
    right: 7%;
    transition: all 0.3s;
    background-color: #1ACE7A;
  }

  .hungup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: all 0.3s;
    background-color: #E72E04;
  }
  
  .credit {
    position: absolute;
    display: block;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Open Sans Condensed', sans-serif;
    color: #fff;
    opacity: 0.4;
  }
  
  a {
    text-decoration: none;
    /* color: #fff; */
  }