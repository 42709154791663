.auth {
    position: absolute;
    width: 100%; 
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .auth-main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner {
    background: #191d24;
  }
  
  .auth.dark {
    background: #222831;
  }
  
  @media screen and (max-width: 900px) {
    .auth {
      grid-template-columns: 0.9fr 1fr;
    }
  }
  
  @media screen and (max-width: 768px) {
    .auth {
      grid-template-columns: 1fr;
    }
    .banner {
      position: absolute;
      grid-template-columns: 1fr 1fr;
      width: 100%; height: 100%;
    }
  
    .strip:nth-child(1),
    .strip:nth-child(2),
    .strip:nth-child(3),
    .strip:nth-child(4) {
      display: none;
    }
  }