a {
    list-style: none !important;
}

a:hover {
    text-decoration: none !important;
}

.pointer {cursor: pointer;}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.list-group-item.active{
    z-index: 1;
}

td > img {
    width: 45px !important;
    height: 45px !important;
    margin: 0 auto;
    display: block;
    background-color: #fff !important;
    object-fit: cover;
    object-position: center;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.p-options .nav-tabs .nav-link.active{
    color: #2f353a;
    background: #f0f3f5;
    border-color: #c8ced3;
    border-bottom-color: #c8ced3;
}
.ordersTable .card-header{
    background-color: #d6e3e8!important
}

.switch-label .switch-slider::after, .switch-label .switch-slider::before,
.switch-label .switch-slider::before,.list-group-item.active{
    z-index:auto!important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cecece;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cecece;
}

:-ms-input-placeholder { /* Microsoft Edge */
    color: #cecece;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #cecece!important;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #cecece!important;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #cecece!important;
}
:-moz-placeholder { /* Firefox 18- */
    color: #cecece!important;
}
label{
    color: #8a8a8a;
}
.form-control{
   color: #34373a!important;
}
.sidebar .nav-dropdown-items .nav-item a {
    padding-left: 40px;
}
.imgWrapp, 
.gallery-folder {
    position: relative;
    margin-bottom: 20px;
    min-height: 50px;
    background-color: #ddd;
    /* background-image: linear-gradient(45deg,rgba(0,0,0,.05) 25%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,.05) 75%,rgba(0,0,0,.05)),linear-gradient(45deg,rgba(0,0,0,.05) 25%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,.05) 75%,rgba(0,0,0,.05)); */
    background-size: 48px 48px;
    background-position: 0 0,24px 24px;
    background-repeat: repeat;
    box-shadow: 0 0 8px #d7d7d7;
}

.imgWrapp img{
    width: 100%;
    height: auto;
    /* box-shadow: 0 0 8px #d7d7d7; */
    max-height: 220px;
    object-fit: contain;
    object-position: center;
}

.editImage {
    position: absolute;
    width: 100%;
    top:0px;
    left: 0;
    opacity: 0;
    transition: opacity .5s;
    width: 100%;
}

.editImage>span{
    position: absolute;
    right: 0px;
}

.editImage>.add{
    position: absolute;
    left: 0px;
}

.editImage>.move {
    position: absolute;
    left: 35px;
}

.editImage>a{
    position: absolute;
    left: 0px;
}

.OptionImage{
    width: 200px;
}

.imageItem{
    position: relative;
    max-height: 220px;
    width: 39%;
    margin-bottom: 25px;
    float: right;
}

.imageItem .form-group{
    position: absolute !important;
    right: 15px;
    top: 0px
}

.close{
    position: absolute!important;
    right: 10px;
    top:10px
}

.imageItem img{
    width:100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.imgWrapp:hover .editImage{
    opacity: 1;
}

.hrhr{
    display: block;
    width: 100%;
    margin: 10px 36px;
}

.crop{
    font-family: sans-serif;

}

.ReactCrop__image{
    height: auto;
    max-height: fit-content!important;
    object-fit: contain;
}

.ReactCrop {
    background-color: #fff !important;
}

/* td span{
    background: #fff;
    display: inline-block;
    margin: 2px;
    border: 1px solid #e4e7ea;
    padding: 2px 6px;
    border-radius: 4px;
} */

.img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

.dropdown-menu{
   width: 320px;
}

td {
    cursor: pointer;
}

td:first-child,
td:last-child {
    cursor: default;
}

.app-header .navbar-toggler {
    outline: none;
}

.table thead th {
    padding: 0.8rem 0.3rem;
}

.table-sm th, .table-sm td {
    vertical-align: middle !important;
}

.table thead tr th,
.table tbody tr td {
    white-space: nowrap;
}

/* .table thead tr th:first-of-type,
.table tbody tr td:first-of-type {
    text-align: center;
} */

.noWrap {
    white-space: nowrap;
}

/* New Changes in Admin */

video {
    outline: none;
}

.el-dialog__headerbtn {
    outline: none !important;
}

.imgWrapp img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.gallery_button {
    background-color: #aaa !important;
    border-radius: 0px !important;
}

.gallery_button i {
    color: #fff;
}

.google_suggest {
    background-color: #f1f5f6;
    border-radius: 2px;
    height: 37px;
    padding: 0 17px;
}