.dropdown {
    position: relative;
    &.open>.dropdown-menu {
      display: block;
    }
    .dropdown-toggle {
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;    
      cursor: pointer;    
      color: #fff;
      background-color: #20a8d8;
      border-color: #2e6da4;
      border: 1px solid transparent;
      border-radius: 4px;
    }
  
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
     
      li {
        cursor: pointer;
        :hover {
          background: #ddd;
        }
        & > a {
          display: block;
          padding: 5px 20px;
          clear: both;
          font-weight: 400;
          line-height: 1.42857143;
          color: #333;
          white-space: nowrap;
          text-decoration: none;
        }
      } 
    }  
  }