@import "../../node_modules/emoji-mart/css/emoji-mart.css";
// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
@import '@coreui/coreui/scss/_dropdown-menu-right.scss';
@import 'languages.scss';
@import 'dropdown.scss';
@import '../projectNulla/sales/Notification/Notification.scss';

@import  '../projectNulla/filter/FilterSlider/Atomic.css';
@import '../projectNulla/filter/FilterSlider/FilterSlider.css';
@import '../projectNulla/globals/Picker/ColorPicker.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
@import "abstracts/variables";
@import "modal";

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
{ 
  -webkit-appearance: none; 
  margin: 0; 
}

/* MODAL */
.modal-title {
    text-align: center;
    color: #4FB2FF !important;
    font-size: 20px;
}

#Simple-Content-Center-Main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 30vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.modal-input {
    position: relative;
	height: 33px;
	width: 100%;
    display: block;
    padding: 0px 8px;
	border: 1px solid #ebebeb;
	letter-spacing: 0.5px;
	font-size: 14px;
    margin: 5px;
    color: #474747;
	&:focus {
        outline-color: #4FB2FF;
        box-shadow: 0px 0px 5px #4FB2FF;
	}
}

.modal-close {
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 5px;
    font-size: 16px;
    color: #4FB2FF;
    border: none;
    background-color: transparent;
    &:focus {
        outline: none;
    }
}

.modal-save {
    display: flex;
    margin: 10px auto 0px;
    padding: 4px 12px;
    border: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    background: #62c462;
    color: #fff;
    &:focus {
        outline: none;
    }
    &:hover {
        background: #4bbb4b;
    }
}
/* MODAL */

/* Tooltip */
.__react_component_tooltip {
    padding: 4px 12px !important;
}
/* Tooltip */

/* ReactSVG */
.reactsvg {
    div,
    svg {
        width: 100%;
        height: 100%;
    }
}
/* ReactSVG */

/* Notification */
.el-notification {
    width: 280px !important;
}
/* Notification */

.list-group-item {
    z-index: 0 !important;
}

.gallery-folder {
    position: relative;
    width: 100%;
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0 0 8px #d7d7d7;
    text-align: center;
    svg {
        width: 24px;
        height: 24px;
    }
}

.folder-name {
    max-width: 120px;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #333;
}

.folderTop {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 1;
}

.folderTop>.delete{
    position: absolute;
    right: 0px;
    z-index: 10;
}

.folderTop>.add{
    position: absolute;
    left: 0px;
    z-index: 10;
}

.folderTop>.move {
    position: absolute;
    left: 35px;
}

.react-switch-bg {
    width: 46px !important;
    height: 18px !important;
    div {
        height: 18px !important;
    }
}

.react-switch-handle {
    width: 16px !important;
    height: 16px !important;
}

.switch-slider {
    &:after {
        font-size: 12px !important;
    }
}

.white-space {
    white-space: nowrap;
}

.word-break {
    word-break: break-word;
}

.card-header {
    padding: 10px !important;
}

.card-body {
    padding: 10px !important;
}

.tab-pane {
    padding: 10px !important;
}

/* EL_DIALOG */ 
.el-dialog--tiny {
    width: 23% !important;
}

.el-dialog__header,
.el-dialog__body,
.el-dialog__footer {
    padding: 10px 20px !important;
}

.el-dialog__header {
    padding-right: 10px !important;
}

.el-dialog__title {
    font-weight: 300 !important;
}

.el-dialog__headerbtn {
    font-size: 12px !important;
}

.el-button {
    padding: 6px 12px !important;
    font-size: 14px !important;
}

@media (max-width: 991px) {
    .el-dialog, 
    .el-dialog--tiny {
        width: 50% !important;
    }
}

@media (max-width: 767px) {
    .el-dialog, 
    .el-dialog--tiny {
        width: 70% !important;
    }
}

@media (max-width: 575px) {
    .el-dialog, 
    .el-dialog--tiny {
        width: 90% !important;
    }
}

@media (max-width: 575px) {
    .nav-tabs {
        .nav-item {
            display: flex !important;
            min-width: 100px !important;
            .nav-link {
                padding: 10px !important;
            }
        }
    }
}

.image-add {
    margin: 4px 0px 0px 0px; 
    border-radius: 0px !important;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.close-btn {
    padding: 2px 6px;
    border-radius: 0px;
    background-color: #aaa;
    border: none;
    cursor: pointer;
    @media (max-width: 767px) {
        background-color: transparent;
    }
}

.back-btn {
    padding: 3px 6px;
}

.main-btns {
    display: flex;
    @media (max-width: 575px) {
        width: 100%;
        flex-direction: column;
    }
}

.mw {
    display: flex;
    min-width: 25px !important;
    a {
        width: 100% !important;
    }
    @media (max-width: 575px) {
        margin: 0px !important;
        margin-bottom: 5px !important;
    }
}

.navbar-toggler {
    padding: 0 !important;
}

.el-pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px !important;
    margin-top: 8px;
}

@media (min-width: 1200px) {
    .five-item {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.addImageModal {
    position: absolute;
    top: 128px;
    left: 234px;
    right: 30px;
    bottom: 40px;
    border: 1px solid rgb(204, 204, 204);
    background-color: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 15px;
    @media (max-width: 991px) {
        left: 30px;
    }
}

.uploadImage {
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;
}

.ReactCrop {
    width: 100% !important;
}

.ReactCrop__image {
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;
}

.tab-content {
    border: none;
}

.nav-tabs .nav-link {
    border: none;
}

.nav-tabs .nav-link.active {
    color: #20A8D8 !important;
}

@media (max-width: 767px) {
    .order--1 {
        order: -1;
        .form-group {
            float: right;
            margin: 0px;
        }
    }
}

.nav-tabs {
    user-select: none;
}

.tab-menu {
    .nav-tabs {
        .nav-item {
            min-width: auto !important;
        }
    }
}

.check {
    display: flex;
    align-items: center;
    padding: 5px;
    input {
        position: relative;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}

.similar-img {
    position: relative;
    img {
        width: 100%;
        height: 140px;
        object-fit: cover;
        object-position: center;
        background-color: #ddd;
    }
}

@media (max-width: 575px) {
    .close--1 {
        order: -1;
        margin-bottom: 0px;
    }
}

/* MEDIA_IMAGES */
.product-image-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    &-check {
        input {
            position: relative;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
    &-title {
        h6 {
            max-width: 80px;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
/* MEDIA_IMAGES */

/* MEDIA_VIDEOS */
/* MEDIA_ICONS */

.lang-col,
.search-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    @media (max-width: 767px) {
        justify-content: flex-start;
    }
}

.search-col {
    input {
        max-width: 386px;
    }
}

.folder-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    i {
        font-size: 32px;
        color: #363636;
    }
}

.orders-body {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #f0f3f5 !important;
    @media (max-width: 991px)  {
        overflow: auto;
    }
    @media (min-width: 992px) {
        overflow: visible;
    }
}

// @media (max-width: 991px) {
//     .orders-body .dropdown-menu {
//         position: static !important;
//     }
// }
// @media (min-width: 992px) {
//     .orders-body {
//         overflow: visible !important;
//     }
// }


/* Hours of Operation */
.operation-hours {
    width: 100%;
    max-width: 660px;
}

.operation-hours-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e6e6e6;
}

.operation-selects {
    select {
        width: 100px;
        height: 30px;
        background: #fff;
        border: 1px solid #999999;
        border-radius: 4px;
        font-size: 14px;
        &:focus {
            outline: none;
            box-shadow: 0 0 4px rgba(0,115,187,0.3);
            border-color: #0073bb;
        }
    }
}

.week-days {
    margin: 0;
    text-transform: capitalize;
    font-weight: 600;
    width: 120px;
}

@media (max-width: 575px) {
    .operation-hours-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .operation-selects {
        margin: 6px 0px;
        select {
            width: 85px;
        }
    }
}
/* Hours of Operation */

/* Additional Information */
.additional-information {
    width: 100%;
    max-width: 660px;
}

.additional-information-item {
    padding: 12px;
    border-top: 1px solid #e6e6e6;
    &:last-child {
        border-bottom: 1px solid #e6e6e6;
    }
}

.additional-name {
    font-weight: 600;
    margin: 0 0 6px;
}

.additional-information label {
    margin: 0px 12px 6px 4px;
}
/* Additional Information */


/* REQUIRED FIELD */
.required-field::after {
    content: " *";
    color: #ff0000;
}
/* REQUIRED FIELD */

/* PAGINATION */
.pagination-block {
    display: flex;
    margin: 10px 0;
    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.pagination {
    margin-bottom: 0px;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        font-size: 14px;
    }
    .active {
        color: #fff;
        border-color: #20a0ff;
        background-color: #20a0ff;
        cursor: default;
    }
}

.btn-prev,
.btn-next {
    padding: 4px 8px;
    margin: 0;
    cursor: pointer;
    color: #97a8be;
    background: #fff;
    border: 1px solid #d1dbe5;
}

.btn-prev {
    border-radius: 2px 0 0 2px;
    border-right: 0;
}

.btn-next {
    border-radius: 0 2px 2px 0;
    border-left: 0;
}

.page-number {
    display: inline-flex;
    justify-content: center;
    min-width: 28px;
    margin: 0;
    padding: 4px 8px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    color: #48576a;
    border: 1px solid #d1dbe5;
    background: #fff;
}

.pagination-select {
    display: inline-flex;
    order: -1;
    margin: 0 12px 0 0;
    .dropdown-menu {
        top: -200px !important;
        left: 0px !important;
    }
    button {
        padding: 4px 8px !important;
        border: 1px solid #d1dbe5 !important;
        border-radius: 2px !important;
        color: #48576a !important;
        background-color: #fff !important; 
        &:focus {
            border-color: #20a0ff !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}
/* PAGINATION */


/* messenger checkbox style */
.conversation-list-checkbox-label {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: auto;
    margin: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .conversation-list-checkbox-input {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
        cursor: pointer;
    }

    .conversation-list-checkbox-checkmark {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 1px solid #e4e7ea;
    }

    .conversation-list-checkbox-input:checked ~ .conversation-list-checkbox-checkmark {
        background-color: #20A8D8;
    }

    .conversation-list-checkbox-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .conversation-list-checkbox-input:checked ~ .conversation-list-checkbox-checkmark:after {
        display: block;
    }

    .conversation-list-checkbox-checkmark:after {
        position: absolute;
        left: 5px;
        top: 0px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
/* messenger checkbox style */

.svg-item {
    svg {
        width: 200px;
        height: 172px;
    }
}

.orderList {
    position: relative;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 6px 0;
        border-bottom: 1px solid #e6e6e6;
        &--status {
            align-items: center;
        }
        &:last-child {
            border-bottom: none;
        }
        &__type {
            display: flex;
            flex: 0 0 70px;
            font-weight: 600;
            margin-right: 10px;
        }
    }

    .status-box {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}


.svg__rotate use {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
}


.form-control-w {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
}

.eyes {
    position: absolute;
    top: 9px;
    right: 8px;
    width: 18px;
    height: 18px;
    line-height: 1;
    cursor: pointer;
    z-index: 99;
  
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .required {
    font-size: .8rem;
    color: #ff0000;
}


.cancel-order {
    &__title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
    }

    &__textarea {
        width: 100%;
        height: 100px;
        resize: none;
    }
}

.is-disabled{
    pointer-events: none;
    opacity: 0.7;
}


.validation {
    // position: absolute;
    top: 100%;
    right: 0;
    margin-top: 0.4rem;
    padding: .5rem 1rem;
    font-size: .6rem;
    font-style: italic;
    letter-spacing: 0.05rem;
    color: #ffffff;
    background-color: #2d3444;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 111;
    &::before {
        content: "";
        // position: absolute;
        top: -8px;
        right: 7%;
        width: 0px;
        height: 0px;
        pointer-events: none;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent #2d3444 transparent;
        overflow: hidden;
        z-index: 1;
    }
}


.avatar-upload {
	position: relative;
	max-width: 20px;
	.avatar-edit {
		position: absolute;
	    left: 50px;
		z-index: 1;
		top: -3px;
		input {
			display: none;
			& + label {
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-bottom: 0;
				border-radius: 100%;
				background: #FFFFFF;
				border: 1px solid transparent;
				box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
				cursor: pointer;
				font-weight: normal;
				transition: all .2s ease-in-out;
				&:hover {
					background: #f1f1f1;
					border-color: #d6d6d6;
				}
				&:after {
					content: "\f040";
					font-family: 'FontAwesome';
					color: #757575;
					position: absolute;
					top: 0px;
					left: 0;
					right: 0;
					text-align: center;
					margin: auto;
				}
			}
		}
	}
	.avatar-preview {
		width: 60px;
		height: 60px;
		position: relative;
		border: 6px solid #F8F8F8;
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
		& > div {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}


.back-to__icon {
    width: 16px;
    height: 16px;
    transform: rotate(0);
}

.back-to {
    display: flex;
    margin: auto;
    padding: 5px;
    cursor: pointer;
}




.input-tag {
    background: white;
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
  }
  
  .input-tag input {
    border: none;
    width: 100%;
  }
  
  .input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .input-tag__tags li {
    align-items: center;
    background: #85A3BF;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }
  
  .input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
  }
  
  .input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
  }

  .product-option-color {
    display: flex;
    flex-wrap: wrap;

    &__title {
        flex-basis: 100%;
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    &__item {
        width: 80px;
        height: 34px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 5px;
    }

    &__item_active {
        border: 2px solid transparent;
        padding: 3px;
    }

    &__inner {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
}

#discount-display {
	background: #d9253a;
	background: linear-gradient(to right, #d9253a, #ff1f39);
	display:inline-block;
	padding: 3px 15px;
	font-size: .7rem;
	border-radius: 2rem;
	position: absolute;
	right: -1px;
	top: -4px;
}

.PhoneInput {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputInput {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    color: #5c6873;
    background-color: #fff;
    border:none;
}

.submit_button {
    background-color: #20a8d8;
    color: #fff;
    border: 0 solid #1985ac;
    border-radius: 4px;
    margin-right: 0.55em;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 0.50em 1em;
    position: relative;
    * {
      transition: opacity 0.3s ease-in;
    }
    .submit-loader {
      position: absolute;
      display: block;
      fill: transparent;
      top: calc(50% - 0.75rem);
      left: calc(50% - 0.75rem);
      width: 1.5rem;
      height: 1.5rem;
      animation: rotating 1.5s linear infinite;
      display: none;
      opacity: 0;
    }
    &.loading {
      span {
        color: transparent;
        opacity: 0;
      }
      .submit-loader {
        fill: transparent;
        display: block;
        opacity: 1;
      }
    }
  }

  .export-buttons {
    display: flex;
    gap: 5px;

    @media screen and (max-width: 900px) {
        .mw {
            flex: 1 1 100%;
        }
    }
  }

  .export_button {
    background-color: #c8ced3;
    color: #23282c;
    border: 0 solid #c8ced3;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 0.50em 1em;
    position: relative;
    * {
      transition: opacity 0.3s ease-in;
    }
    .submit-loader {
      position: absolute;
      display: block;
      fill: transparent;
      top: calc(50% - 0.75rem);
      left: calc(50% - 0.75rem);
      width: 1.5rem;
      height: 1.5rem;
      animation: rotating 1.5s linear infinite;
      display: none;
      opacity: 0;
    }
    &.loading {
      span {
        color: transparent;
        opacity: 0;
      }
      .submit-loader {
        fill: transparent;
        display: block;
        opacity: 1;
      }
    }
  }
  
  @-webkit-keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
  

.customer__create {
    background-color: transparent;
    border: 1px solid #d9d9d9;
    height: 36px;
    width: 120px;
    border-radius: 3px;
    display: block;
    color: #a7a6a6;
    text-align: center;
}

#emojis {
    bottom: 100px;
    left: 550px;
    position: absolute;
    background: transparent;
    box-shadow: none;
  
      .emoji-mart-bar:last-child {
        display: none;
      }
      .emoji-mart {
        border: 1px solid rgba(0, 0, 0, 0.1);
        .emoji-mart-search {
          .emoji-mart-search-icon {
            top: 8px;
            position: absolute;
            left: 16px;
          }
          input {
            border: 1px solid #eee;
            padding: 6px 5px 6px 30px;
            background: #f1f1f4;
            font-size: 14px;
            color: #666;
          }
        }
    }
  }


  .price-style-1 {
    del {
      color: rgba(red, 0.5);
      text-decoration: none;
      position: relative;
      &:before {
        content: " ";
        display: block;
        width: 100%;
        border-top: 2px solid rgba(red, 0.2);
        height: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: rotate(-7deg);
      }
    }
}

input {
    &:disabled{
       background-color: transparent;
    }
}

td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.Select-control {
    border: 1px solid #e4e7ea;
}

.pre-order {
    min-height: 24px;
    align-items: center;
    width: 100%;
    cursor: initial;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;

    &__radio {
        position: absolute;
        overflow: hidden;
        opacity: 0;
        padding: 0;
        border: 0;
        height: 0;
        width: 0;
    }

    &__icon {
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        min-width: 16px;
        height: 16px;
        border: 1px solid  #3899EC;
        background-color: #FFFFFF;
        border-radius: 50%;
        cursor: pointer;
    }

    &__icon::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
        margin: 2px;
        background-color: var(--background-color);
    }

    &__radio-label {
        padding-inline-start: 12px;
        width: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 0;

        &__text {
            font-family: "Helvetica Neue",Helvetica,Arial,"\30E1\30A4\30EA\30AA","meiryo","\30D2\30E9\30AE\30CE\89D2\30B4 pro w3","hiragino kaku gothic pro", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color:  #8a8a8a;
        }
    }

    &-input-container {
        flex-direction: row;
        display: inline-flex;
        gap: 0;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;

        &__wrapper {
            padding: 0 6px;
            display: flex;
            width: 100%;

            input {
                border-radius: 6px;
                border: solid 1px #C1E4FE;
            }
        }
    }
}

.textarea-wrapper {
    max-width: fit-content;
    margin-top: 10px;
    position: relative;
}

.textarea-wrapper__icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
}

.upload-box {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;

    .file-upload {
        position: relative;
        border-radius: 8px;
        overflow: hidden;

        span {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 5px;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: white;
            backdrop-filter: blur(10px);
            cursor: pointer;
        }
    }
}

.emojy-box {
    position: absolute;
    left: -75px;
    bottom: -100px;
}

.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh !important;
  max-height: 100dvh !important; 
}

menu-fixed, .main {
    margin-right: 0 !important;
}

.aside-menu-lg-show .aside-menu {
    width: 900px !important;
    margin-right: 0 !important;   
}

.btn-custom-orange {
    color: #fff;
    background-color: #FFA500;
    border-color: #FFA500;
}