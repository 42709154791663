// SHADOWS
$shadow-primary: 0 3px 6px rgba(#000, .16);
$shadow-secondary: 0 0 12px #E7EAF0;

// COLORS
$color-secondary: #7f9b9a;
$color-tertiary: #D3DC5E;
$color-quaternary: #F86868;
$color-grey-light: #f5f5f5;

// $color-white: #fff;
// $color-black: #000;
$color-black-secondary: #313131;
// $color-orange: #F1A55A;
$color-orange-hover: #de9004;
$color-blue-light: #04388c;
$color-yogurt: #04667B;
$color-grey-bg: #FBFBFB;
$color-btn-blue: #0096B2;

// BORDERS
$main-border-color: #01a8a3;
$secondary-border-color: #748A9D;

// GRADIENTS
$radial-gradient: radial-gradient(rgba(83, 42, 167, .32), rgba(35, 22, 151, .74));
$linear-gradient-left: linear-gradient(to left, rgba(167, 35, 255, 1), rgba(3, 21, 68, 1));
$linear-gradient-right: linear-gradient(to right, rgba(167, 35, 255, 1), rgba(3, 21, 68, 1));



//------ Orange Life

// COLORS
$color-general: #a9cbfe;

$color-primary: #656565;
$color-black: #000; 
$color-black-dark: #202020;
$color-black-light: #5B5B5B; 
$color-white: #fff;
$color-grey: #e1e1e1;
$color-grey-dark: #ebebeb; 
$color-grey-secondary: #f6f6f6; 
$color-orange: #f1a55a;
$color-orange-primary: #FF875D;
$color-orange-dark: #ff6500;
$color-orange-secondary: #F69F18;
$color-orange-tertiary: #FFB69C;
$color-green: #668d54;
$color-purple: #893CFA;
$color-pink: #ffb69c;

$color-img-secondary: #e9e6e3; 
