.action {
    margin: 10px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.forgot {
    text-decoration: underline;
    cursor: pointer;
}
.footer {
    display: flex;
    gap: 5px;
    margin: 0 0 0 0;
}
.footer .text {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.footer .btn {
    text-decoration: underline;
    cursor: pointer;
}

.shipox-iframe {
    flex: 1 1;
    border: none;
    top: 0; 
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 0px;
    bottom: 0;
    width: 100%;
    height: 900px;
}