.call-modal {
    position: absolute;
    width: 250px;
    padding: 20px;
    left: calc(50vw - 125px);
    top: calc(50vh - 200px);
    background: -webkit-linear-gradient(top, #074055 0%, #030D10 100%);
    background: -moz-linear-gradient(top, #074055 0%, #030D10 100%);
    background: -o-linear-gradient(top, #074055 0%, #030D10 100%);
    background: linear-gradient(to bottom, #074055, #030D10);
    background-color: #212529;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    display: none;
}

.call-modal.active {
    display: block;
    background: linear-gradient(to top, #074055 0%, #030D10 100%);
    background-color: #212529;
    z-index: 9999;
    -webkit-animation: blinking 3s infinite linear;
    -moz-animation: blinking 3s infinite linear;
    -o-animation: blinking 3s infinite linear;
    animation: blinking 3s infinite linear;
    text-align: center;
    text-align: -webkit-center;
}

@keyframes blinking {
    25% {
        transform: scale(0.96)
    }
    50% {
        transform: scale(1)
    }
    75% {
        transform: scale(0.96)
    }
    100% {
        transform: scale(1)
    }
}

.video-control .btn-action,
.call-modal .btn-action {
    height: 40px;
    width: 40px;
    line-height: 10px;
    margin: 0px 8px;
    font-size: 1.2em;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 0.25s;
}

.btn-action {
    outline: none;
    border: none;
}

.btn-action:focus {
    outline: none;
    border: none;
}

.hangup {
    transform: rotate(-135deg);
}

.call-modal p {
    font-size: 1.5em;
}

.call-modal span.caller {
    color: #00AFF0;
}

.call-modal .btn-action:not(.hangup) {
    background-color: rgb(76, 175, 80);
}

.call-modal button {
    color: white;
}

.target-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 30px 0px;
}

.target-avatar img {
    width: 100%;
    height: 100%;
}

.call-modal span.caller {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.incoming {
    display: block;
    font-size: 14px;
    color: rgb(150, 150, 150);
    margin-bottom: 20px;
}