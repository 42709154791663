.call-window {
	position: absolute;
	top: 50%;
    left: 50%;
    border-radius: 15px;
    background-color: #2f323a;
    width: 550px;
	height: 630px;
	transform: translate(-50%, -50%);
	opacity: 0;
	z-index: 9996;

	@media screen and (max-width: 800px) {
		width: 100vw;
		height: calc(100% - 120px);
	}

	&.active {
		opacity: 1;
		z-index: 9997;
		.video-control {
			z-index: 9998;
		}
	}
	
	.video-control {
		position: absolute;
		bottom: 20px;
		height: 72px;
		width: 100%;
		text-align: center;
		opacity: 1;
		z-index: -1;
		
		&:hover {
			opacity: 1;
		}
	}
	
}


.video-control, .call-modal {
	.btn-action {
		$height: 50px;
		height: $height;
		width: $height;
		line-height: $height;
		margin: 0px 8px;
		font-size: 1.4em;
		text-align: center;
		border-radius: 50%;
		cursor: pointer;
		transition-duration: 0.25s;
		
		&:hover {
			opacity: 0.8;	
		}
		
		&.hangup {
			background-color: #E81123;
		}
		
		&:not(.hangup) {
			background-color: #00AFF0;
			
			&.disable {
				background-color: #E81123;
			}
		}
	}
}