.call-modal {
	position: absolute;
	width: 400px;
	padding: 20px;
	left: calc(50vw - 200px);
	top: calc(50vh - 60px);
	text-align: center;
	display: none;

	&.active {
		display: block;
		z-index: 9999;
	}

	.btn-action:not(.hangup) {
		background-color: 'green';
	}

	span.caller {
		color: #00AFF0;
	}

	p {
		font-size: 1.5em;
	}
}