.keypad {
    display: grid;
    list-style: none;
    padding: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 2px;
  }
  .keypad li {
    display: flex;
    justify-content: center;
  }
  
  .call-input {
    font-size: 20px;
    border-color: transparent;
    width: calc(100% - 1.2rem);
    background: white;
    text-align: center;
    margin: .1em .6rem;
    border-radius: 30px;
  }
  