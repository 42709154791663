.pagination button:focus {
    outline: none;
}

.dropdown-menu {
    max-height: 246px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 0px;
}

.caret {
	  float: right;
    margin-top: 5%;
}

#menu1 {
    width: 160px; 
    text-align: left;
}
