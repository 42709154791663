.video-contact {
    width: 40%;
    aspect-ratio: 1 / 1.5;
    position: absolute;
    right: 8px;
    top: 8px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
    z-index: 20;
}

.video-user {
    position: absolute;
    transform: rotateY(180deg);
    justify-content: center;
    border-radius: 15px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    overflow: hidden;
}

.video-control {
    position: absolute;
    bottom: 92px;
    left: 50%;
    transform: translate(-50%);
    opacity: 1;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.video-control button {
    color: white;
}

button.fa-video,
button.fa-microphone {
    background-color: rgb(76, 175, 80);
}

button.hangup {
    background-color: rgb(244, 67, 54);
}

.call-modal .btn-action,
.video-control .btn-action {
    height: 40px;
    width: 40px;
    line-height: 10px;
    margin: 0 8px;
    font-size: 1.2em;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: .25s;
}

.btn-action:focus {
    outline: none;
    border: none;
}

.video-contact:hover .max-mark {
    display: block;
}

.toggle-contact-video {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(23, 35, 34, .75);
    cursor: pointer;
}

.toggle-contact-video:hover {
    background-color: rgba(14, 226, 208, 0.75);
}

.toggle-contact-video img {
    width: 100%;
    height: 100%;
}

.max-mark {
    top: 3px;
    right: 3px;
    display: none;
}

.min-mark {
    bottom: 5px;
    left: 0px;
}

.max-video {
    max-width: 100%;
    overflow: hidden;
    border-radius: 8px;
    opacity: 1;
}

.min-video {
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    /* .video-user {
        right: 47px;
    } */
}
