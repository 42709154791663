  .statistics-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .item-statistics { 
    padding: 4px;
    box-sizing: border-box;
    flex: 1 0;
    max-width: 420px;
    min-width: 300px;
    background-color: #fff;
  }
  
  .box {
    padding: 3px;
    max-height: 444px;
    box-shadow: 0px 1px 22px -12px #607D8B;
    background-color: #fff;
  }
  
  #monthly-earnings-chart #apexcharts-canvas {
    position: relative;
  }
  #monthly-earnings-chart #apexcharts-canvas:after {
    content: "";
    position: absolute;
    left: 0;
    right: 58%;
    top: 0;
    bottom: 0;
    background: #24bdd3;
    opacity: 0.65;
  }
  
  #monthly-earnings-chart #apexcharts-title-text {
    font-weight: 600;
  }
  #monthly-earnings-chart #apexcharts-subtitle-text {
    font-weight: 700;
  }

  .monthly-earnings-text {
    position: absolute;
    left: 70px;
    top: 187px;
    color: #fff;
    z-index: 10;
  }
  
  .monthly-earnings-text h6 {
    font-size: 16px;
  }

  .chart-title h5 {
    font-size: 18px;
    color: rgba(51,51,51,1);
    margin-bottom: 38px;
  }
  
  .apexcharts-subtitle-text {
    fill: #8799a2 !important; 
  }

  .apexcharts-title-text {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    fill:rgb(38, 50, 56) !important; 
    opacity: 1;
  }
  
  @media screen and (max-width:760px) {
    .monthly-earnings-text {
      left: 30px;
    }
    .box {
      padding: 25px 0;
    }
  }
  
  .sparkboxes .box {
    padding: -2px 0 0 0;
    position: relative;
  }
  
  #spark {
    position: relative;
    padding-top: 15px;
  }

  .top-align {
    vertical-align: top !important;
  }

  .top-align:nth-child(2) {
    width: 30%;
  }

  .top-align:nth-child(3) {
    width: 20%;
  }

  .top-align:nth-child(4) {
    width: 20%;
  }
  
  
  /* overrides */
  .sparkboxes #apexcharts-subtitle-text { fill: #8799a2 !important; }