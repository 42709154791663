.new-checkbox, .new-checkbox label {
  width: 400px;
  cursor: pointer
}

.new-checkbox label {
  text-align: left;
  float: right;
  font-size: 0.775rem;
}

.new-checkbox input {
  margin-top: 2px;
  margin-right: 16px;
  float: left;
  cursor: pointer
}