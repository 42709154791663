.outboundCard {
    position: fixed;
    overflow: hidden;
    width: 250px;
    height: fit-content;
    height: 90px;
    top: 0;
    right: 10%;
    border-radius: 15px;
    background-color: #2f323a;
    z-index: 1030;
  }
  
  .outboundHeader {
    position: relative;
    width: 100%;
    height: fit-content;
    max-height: 80%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: transparent;
  }
  
  .outboundPhoneNumber {
    width: 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }
